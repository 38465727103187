.playlists-list {

  .playlists-list-section {
    background-color: $color-primary-green;
    color: $color-white;

    .section-heading {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    .helper-container {
      display: flex;
      flex-direction: column;
    }

    .left-column {
      width: 100%;
    }

    .right-column {
      width: 100%;
    }

    .intro {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .img-container {
        min-height: 200px;
        max-height: 370px;
        cursor: pointer;
      }

      .img {
        display: block;
        max-height: 370px;
        object-fit: cover;
        max-width: 100%;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .credits {
        font-size: 12px;
        font-family: $font-sans, sans-serif;
        margin-bottom: 15px;
      }

      .text {
        background-color: $color-primary-green-light;
        font-size: $paragraph-medium;
        color: $color-grey-medium;
        padding: 15px 20px;
        flex: 1;

        .heading {
          font-size: $heading-large;
        }
        .radio-station {
          font-size: $heading-big;
        }
        .description {
          font-weight: 300;
          line-height: 1.6;
          white-space: pre-line;
        }
      }
    }

    .years-list {
      margin-bottom: 10px;
      min-height: 45px;
      display: flex;
      flex-wrap: wrap;

      .item {
        height: 35px;
        width: 60px;
        font-size: $paragraph-small;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-tertiary-black;
        border: 1px solid $color-tertiary-black;
        cursor: pointer;

        &:hover {
          color: $color-black;
          background-color: $color-secondary-yellow;
        }
      }

      .active {
        color: $color-black;
        background-color: $color-secondary-yellow;
      }
    }

    .right-column-no-intro {
      .years-list {
        margin-bottom: 20px;
      }
    }

    .months-list {
      display: flex;
      flex-wrap: wrap;

      .months-item {
        margin-bottom: 10px;
        width: 100%;
      }

      .month {
        margin-bottom: 10px;
        display: inline-block;
        font-size: $heading-small;
        background-color: $color-tertiary-black;
        width: 100%;
        height: 35px;
        padding: 9px 10px;
      }

    }

    .dates-list {
      display: flex;
      flex-direction: column;

      .dates-item {      
        cursor: pointer;
        margin-bottom: 10px;
      }

      .dates-link {
        display: flex;
        height: 60px;

        .date {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $color-primary-green-lighter;
          width: 60px;
          height: 100%;
          font-size: $heading-medium;
        }
        .playlist-title {
          background-color: $color-primary-green-light;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 100%;
          font-size: $paragraph-small;
          flex: 1;
        }
         .truncate-text {
            display: block;
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;
            max-height: 48px;
            line-height: 24px;
          }
      }
    }


  }
  .most-played-tracks {
    background-color: $color-primary-green-dark;

     .section-wrapper {
      height: auto;

      .slick-slider {
        .slider-item {}
      }

      .slick-slider {

        .slider-item {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          outline: none;
        }

        .slider-item {

          .img-container {
            width: 200px;
            height: 115px;
          }

          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .artist {
            height: 85px;
            font-size: $heading-small;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: $color-primary-green-lighter;
            width: 200px;
            padding: 0 8px;

          }
        }

        .placeholder-img {
            width: 200px;
            height: 135px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-primary-green;
            color: $color-grey;
          }

          .description {
            height: 90px;
            font-size: $heading-small;
            background-color: $color-primary-green-lighter;
            width: 200px;
            text-decoration: none;

            .track-name {
              display: flex;
              color: $color-white;
              flex-direction: column;
              text-align: center;
              text-overflow: ellipsis;
              word-wrap: break-word;
              overflow: hidden;
              max-height: 80px;
              line-height: 20px;
              max-width: 100%;
            }
          }

        .slick-arrow {
          z-index: 2;
        }

        .slick-prev {
          left: 0;
        }

        .slick-next {
          right: 0;
        }
      }

      .list {
        display: flex;

        .item {
          width: calc(25% - 20px);
          margin: 10px;
          height: 200px;
          background-color: $color-primary-green-light;
        }
      }
    }
  }

}

@supports (-webkit-line-clamp: 3) {
  .playlists-list {
    .playlists-list-section {
      .dates-list {
        .dates-link {
           .truncate-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            max-height: none;
            line-height: initial;
          }
        }
      }
    }
   .most-played-tracks {
     .section-wrapper {
      .slick-slider {
          .description {
            .track-name {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              max-height: none;
              line-height: initial;
            }
          }
      }
    }
  }

  }
}

@media screen and (min-width: $breakpoint-mobile-small) {
  .playlists-list {

  .playlists-list-section {

    .years-list {
      .item {}
    }

    .months-list {
      .months-item {
        width: calc(50% - 5px);

        &:nth-of-type(even) {
          margin-right: 0;
        }

        &:nth-of-type(odd) {
          margin-right: 10px;
        }
      }
      .month {}
    }

    .dates-list {
      .dates-item {}
    }
  }
  .most-played-tracks {
     .section-wrapper {
      height: auto;

      .slick-slider {

        .slider-item {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          outline: none;
        }

        .slider-item {
          .img-container {
            width: 200px;
            height: 135px;
          }

          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }

          .placeholder-img {
            width: 200px;
            height: 135px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-primary-green;
            color: $color-grey;
          }

          .description {
            height: 90px;
            font-size: $heading-medium;
            font-weight: 300;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: $color-primary-green-lighter;
            width: 200px;
            text-decoration: none;
            padding: 0 8px;
          }
        }

        .slick-arrow {
          z-index: 2;
        }

        .slick-prev {
          left: calc(50% - 160px);
        }

        .slick-next {
          right: calc(50% - 160px);
        }
      }

      .list {
        display: flex;

        .item {
          width: calc(25% - 20px);
          margin: 10px;
          height: 200px;
          background-color: $color-primary-green-light;
        }
      }
    }
  }

}
}

@media screen and (min-width: $breakpoint-mobile) {
  .playlists-list {
    .playlists-list-section {
      .years-list {
        .item {}
      }

      .months-list {

        .months-item {

            &:nth-of-type(even) {
            }

            &:nth-of-type(odd) {
            } 
        }
        .month {}
      }

      .dates-list {
        .dates-item {}
        .link {}
      }
    }
    .most-played-tracks {
       .section-wrapper {
        height: auto;

        .slick-slider {

          .slider-item {
            display: flex !important;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            outline: none;
          }

          .slider-item {
            .img-container {
              width: 200px;
              height: 135px;
            }
            .img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
            }

            .placeholder-img {
              width: 200px;
              height: 135px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $color-primary-green;
              color: $color-grey;
            }

            .description {
              height: 90px;
            }
          }

          .slick-arrow {
            z-index: 2;
          }

          .slick-prev {
            left: 0;
          }

          .slick-next {
            right: 0;
          }
        }

        .list {
          display: flex;

          .item {
            width: calc(25% - 20px);
            margin: 10px;
            height: 200px;
            background-color: $color-primary-green-light;
          }
        }
      }
    }

  }
}

@media screen and (min-width: $breakpoint-tablet) {
  .playlists-list {

    .playlists-list-section {

      .intro {
        flex-direction: row;
        .img-container {}

        .credits {

        }

        .img {
          width: 300px;
          height: auto;
        }
      }

      .years-list {
        margin-bottom: 20px;

        .item {}
      }

      .months-list {
        margin: 0;

        .months-item {
          margin-bottom: 15px;
          width: calc(50% - 7.5px); 

          &:nth-of-type(even) {
            margin: 0;
            margin-right: 0;
            margin-bottom: 15px;
          }

          &:nth-of-type(odd) {
            margin: 0;
            margin-right: 15px;
            margin-bottom: 15px;
          }
        }

        .month {}

      }

      .dates-list {
        .dates-link {

          &:hover .date {
            color: $color-black;
            background-color: $color-secondary-yellow;
          }
        }
      }
    }
    .most-played-tracks {
      .slick-slider {

        .slider-item {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          outline: none;
        }

        .slider-item {
          .img-container {
            width: 200px;
            height: 115px;
          }
          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &:hover .description {
            background-color: $color-primary-green-hover;
          }
        }

        .description:hover {
          
        }

        .slick-arrow {
          z-index: 2;
        }

        .slick-prev {
          left: -40px;
        }

        .slick-next {
          right: -40px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-small) {
  .playlists-list {

    .playlists-list-section {

      .helper-container {
        flex-direction: row;
      }

      .left-column {
        width: 300px;
        margin-right: 30px;
      }

      .right-column {
        flex: 1;
      }

      .intro {
        flex-direction: column;
        width: 300px;

        .credits {
   
        }
        
        .img {
          max-height: none;
        }

        .text {
          .heading {}
          .description {}
        }
      }

      .right-column-no-intro {
        width: 100%;

        .months-list { 
          width: 75%;
        }
      }

      .img {
        max-width: 100%;
      }

      .months-list {
        margin: -7.5px;

        .months-item {
          width: calc(33.3% - 15px); 

            &:nth-of-type(even) {
              margin: 7.5px;
            }

            &:nth-of-type(odd) {
               margin: 7.5px;
            } 
        }
      }
      .dates-list {
        .dates-item {}
        .link {}
      }
    }
    .most-played-tracks {
       .section-wrapper {
        .slick-slider {
          .slick-prev {
            left: 0px;
          }
          .slick-next {
            right: 0px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-large) {
  .playlists-list {
  .playlists-list-section {

    .programme-name {
      margin: 20px 0 30px;
      font-size: $heading-big;
      font-family: $font-serif, serif;
    }

    .years-list {
      margin-bottom: 25px;

      .item {}
    }

    .months-list {
      .months-item {
        &:nth-of-type(even) {}
        &:nth-of-type(odd) {}
      }
      .month {}
    }

    .dates-list {
      .dates-item {}
      .link {}
    }
  }
  .most-played-tracks {
     .section-wrapper {
      max-width: 1160px;
      height: 362px;

      .slick-slider {

        .slider-item {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          outline: none;
        }

        .slider-item {
          .img-container {
            width: 200px;
            height: 135px;
          }
          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }

          .placeholder-img {
            width: 200px;
            height: 135px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-primary-green;
            color: $color-grey;
          }
        }

        .slick-arrow {
          z-index: 2;
        }

        .slick-prev {
          left: -20px;
        }

        .slick-next {
          right: -20px;
        }
      }

      .list {
        display: flex;

        .item {
          width: calc(25% - 20px);
          margin: 10px;
          height: 200px;
          background-color: $color-primary-green-light;
        }
      }
    }
  }

}
}
