.manage-content-page {
  background-color: $color-grey-light;

  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 128px);
    max-width: 1200px;
    margin: auto;
    background-color: $color-white;

    .main {
      padding: 10px;
      background-color: $color-white;

      .section-heading {
        font-size: $heading-big;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .input-group {
        position: relative;
        display: inline-block;
        margin-top: 20px;
        width: 100%;
        max-width: 300px;
      }

      .input {     
        padding: 6px 12px;
        width: 100%;
        font-size: $paragraph-medium;
        border: 1px solid $color-grey;
        outline: none;
      }

      .reset-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $color-grey;
        cursor: pointer;
      }

      .filters-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
      }

      .date-input {
        width: 100%;
        margin-bottom: 10px;

        input {
          width: 100%;
          padding: 6px 12px;
        }
      }

      .react-datepicker-wrapper {
        width: 100%;
      }

      .date-input input,
      .filter {
        font-size: $paragraph-medium;
        font-family: $font-sans, sans-serif;
        border: 1px solid $color-grey;
        outline: none;
      }

      .filter {
        width: 100%;
        padding: 5px 12px;
      }

      .react-datepicker__day--selected {
        background-color: $color-secondary-yellow;
      }

      .list {
        margin: 15px 0;

        .item {
          list-style-type: none;
          font-size: $paragraph-medium;
          font-family: $font-sans, sans-serif;
          display: flex;
          align-items: center;
        }
      }

      .no-results {
        font-size: $paragraph-medium;
        font-family: $font-sans, sans-serif;
        margin-left: 15px;
      }
    }

    .add-icon {
      cursor: pointer;
    }

    .manager-list {
      margin-top: 20px;

      .item {
        background-color: $color-grey-whitesmoke;
        margin-bottom: 15px;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
      }

      .img-container {
        width: 120px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-grey-light;
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }

      .img-no-crop {
        max-width: 100%;
        height: 100px;
      }

      .item-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;

        .primary {  
          margin-bottom: 3px;
        }

        .secondary {
          font-weight: 300;
        }
      }

      .helper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }

      .date {
        font-weight: 300;
        padding: 10px;
      }

      .action-buttons {
        padding: 10px;
        
        .button {
          cursor: pointer;
        }

        .action-button-wrapper {
        }

        .delete-button-wrapper {
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-mobile-small) {
  .manage-content-page {
  background-color: $color-grey-light;

  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 128px);
    max-width: 1200px;
    margin: auto;
    background-color: $color-white;

    .main {
      padding: 10px;
      background-color: $color-white;

      .section-heading {
        font-size: $heading-big;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .input-group {
        position: relative;
        display: inline-block;
        margin-top: 20px;
        width: 100%;
        max-width: 300px;
      }

      .input {     
        padding: 6px 12px;
        width: 100%;
        font-size: $paragraph-medium;
        border: 1px solid $color-grey;
        outline: none;
      }

      .reset-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $color-grey;
        cursor: pointer;
      }

      .filters-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
      }

      .date-input {
        width: 130px;
        margin-right: 10px;
        margin-bottom: 0;

        input {
          width: 100%;
          padding: 6px 12px;
        }
      }

      .date-input input,
      .filter {
        font-size: $paragraph-medium;
        font-family: $font-sans, sans-serif;
        border: 1px solid $color-grey;
        outline: none;
      }

      .filter {
        width: 160px;
        padding: 5px 12px;
      }

      .list {
        margin: 15px 0;

        .item {
          list-style-type: none;
          font-size: $paragraph-medium;
          font-family: $font-sans, sans-serif;
          display: flex;
          align-items: center;
        }
      }

      .no-results {
        font-size: $paragraph-medium;
        font-family: $font-sans, sans-serif;
        margin-left: 15px;
      }
    }

    .add-icon {
      cursor: pointer;
    }

    .manager-list {
      margin-top: 20px;

      .item {
        background-color: $color-grey-whitesmoke;
        margin-bottom: 15px;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
      }

      .img-container {
        width: 120px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-grey-light;
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }

      .img-no-crop {
        max-width: 100%;
        height: 100px;
      }

      .item-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;

        .primary {  
          margin-bottom: 3px;
        }

        .secondary {
          font-weight: 300;
        }
      }

      .helper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }

      .date {
        font-weight: 300;
        padding: 10px;
      }

      .action-buttons {
        padding: 10px;
        
        .button {
          cursor: pointer;
        }

        .action-button-wrapper {
        }

        .delete-button-wrapper {
        }
      }
    }
  }
}
}


@media screen and (min-width: $breakpoint-tablet) {
    .manage-content-page {

    .wrapper {
      flex-direction: row;

      .main {
        padding: 30px;

        .section-heading {
          font-size: $heading-extra-large;
        }

        .input-group {}

        .input {     
          padding: 6px 12px;
          font-size: $paragraph-medium;
        }

        .reset-icon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          color: $color-grey;
          cursor: pointer;
        }

        .filters-wrapper {
          display: flex;
          margin-top: 30px;
        }

        .date-input {
          input {
            padding: 6px 12px;
          }
        }

        .date-input input,
        .filter {
          font-size: $paragraph-medium;
          font-family: $font-sans, sans-serif;
          border: 1px solid $color-grey;
          outline: none;
        }

        .filter {
          width: 160px;
          padding: 5px 12px;
        }

        .list {
          margin: 15px 0;

          .item {
            list-style-type: none;
            font-size: $paragraph-medium;
            font-family: $font-sans, sans-serif;
            display: flex;
            align-items: center;
          }
        }

        .no-results {
          font-size: $paragraph-medium;
          font-family: $font-sans, sans-serif;
          margin-left: 15px;
        }
      }

      .manager-list {
        margin-top: 20px;

        .item {
          background-color: $color-grey-whitesmoke;
          margin-bottom: 15px;
          list-style-type: none;
          display: flex;
          justify-content: space-between;
          max-width: 500px;
        }

        .img-container {
          width: 150px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-grey-light;
        }

        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }

        .img-no-crop {
          max-width: 100%;
          height: 100px;
        }

        .item-info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 15px;

          .primary {  
            margin-bottom: 3px;
          }

          .secondary {
            font-weight: 300;
          }
        }

        .helper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
        }

        .date {
          font-weight: 300;
          padding: 10px;
        }

        .action-buttons {
          padding: 10px;
          
          .button {
            cursor: pointer;
          }

          .action-button-wrapper {
          }

          .delete-button-wrapper {
          }
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-small) {
  .manage-content-page {

  .wrapper {
     min-height: calc(100vh - 168px);

    .main {
      padding: 30px;

      .section-heading {
        font-size: $heading-extra-large;
      }

      .input-group {}

      .input {     
        padding: 6px 12px;
        font-size: $paragraph-medium;
      }

      .reset-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $color-grey;
        cursor: pointer;
      }

      .filters-wrapper {
        display: flex;
        margin-top: 30px;
      }

      .date-input {
        width: 180px;
        margin-right: 15px;

        input {
          padding: 6px 12px;
        }
      }

      .date-input input,
      .filter {
        font-size: $paragraph-medium;
        font-family: $font-sans, sans-serif;
        border: 1px solid $color-grey;
        outline: none;
      }

      .filter {
        width: 200px;
        padding: 5px 12px;
      }

      .list {
        margin: 15px 0;

        .item {
          list-style-type: none;
          font-size: $paragraph-medium;
          font-family: $font-sans, sans-serif;
          display: flex;
          align-items: center;
        }
      }

      .no-results {
        font-size: $paragraph-medium;
        font-family: $font-sans, sans-serif;
        margin-left: 15px;
      }
    }

    .manager-list {
      margin-top: 20px;

      .item {
        background-color: $color-grey-whitesmoke;
        margin-bottom: 15px;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        max-width: 500px;
      }

      .img-container {
        width: 150px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-grey-light;
      }

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }

      .img-no-crop {
        max-width: 100%;
        height: 100px;
      }

      .item-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;

        .primary {  
          margin-bottom: 3px;
        }

        .secondary {
          font-weight: 300;
        }
      }

      .helper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }

      .date {
        font-weight: 300;
        padding: 10px;
      }

      .action-buttons {
        padding: 10px;
        
        .button {
          cursor: pointer;
        }

        .action-button-wrapper {
        }

        .delete-button-wrapper {
        }
      }
    }
  }
}
}

@media screen and (min-width: $breakpoint-desktop-large) {
  
}
