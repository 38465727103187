.ReactModalPortal {

  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
    z-index: 12;
    // opacity: 0;
    // transition: all .2s ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    // opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    // opacity: 0;
  }

  .edit-form-modal {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: none !important;
    transform: none !important;
    max-width: 600px;
    width: 100% !important;
    height: 100% !important;
    outline: 0;
    overflow: auto !important;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    background-color: $color-white;
  }

  .delete-prompt-modal {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    bottom: none !important;
    right: none !important;
    transform: translate(-50%, -50%) !important;
    max-width: 400px !important;
    max-height: 400px !important;
    height: auto !important;
    outline: 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    background-color: $color-white; 
  }

  .lightbox-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    bottom: none !important;
    right: none !important;
    transform: translate(-50%, -50%) !important;
    max-width: 90% !important;
    max-height: 90% !important;
    height: auto !important;
    outline: 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);

    .img {
      max-height: 80vh;
      max-width: 100%;
      display: block;
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 25px;

    .heading {
      border-bottom: 4px solid $color-secondary-yellow;
      display: inline-block;
    }

    .button {
      border: none;
      padding: 8px 14px;
      font-size: $heading-small;
      font-family: $font-sans, sans-serif;
      cursor: pointer;
      outline: none;
    }

    .action-button {
      background-color: $color-secondary-yellow;
    }

    .delete-button {
      background-color: $color-red;
    }

    .dismiss-button {
      background-color: $color-grey-light;
    }
  }
  
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (min-width: $breakpoint-mobile) {

}


@media screen and (min-width: $breakpoint-tablet) {
  .ReactModalPortal {
    .lightbox-image {
      width: auto;
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-small) {
  .ReactModalPortal {
    .ReactModal__Content {

    }
  }
}

@media screen and (min-width: $breakpoint-desktop-large) {
  
}
