.youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: $breakpoint-mobile) {

}


@media screen and (min-width: $breakpoint-tablet) {

}

@media screen and (min-width: $breakpoint-desktop-small) {

}

@media screen and (min-width: $breakpoint-desktop-large) {
  
}