.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $color-primary-green;
  min-height: calc(100vh - 90px);
}

@media screen and (min-width: $breakpoint-mobile) {

}


@media screen and (min-width: $breakpoint-tablet) {
  
}

@media screen and (min-width: $breakpoint-desktop-small) {
  min-height: calc(100vh - 120px);
}

@media screen and (min-width: $breakpoint-desktop-large) {
  
}