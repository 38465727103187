.playlists-search {

  .search-section {
    background-color: $color-primary-green;
    color: $color-white;

    .tabs {
      display: flex;
      margin-bottom: 20px;

      .tab {
        width: 50%;
        padding: 8px 6px;
        background-color: $color-primary-green-lighter;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .active-tab {
        background-color: $color-primary-green;
      }

      h2 {
        font-size: $heading-medium;
        display: flex;
        align-items: center;
      }

      .icon {
        display: none;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    .section-heading {
      display: none;
    }

    .top-column {
      flex: 1;
      width: 100%;

      .categories-list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;

        .item {
          flex: 1;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $heading-small;
          cursor: pointer;
          background-color: $color-tertiary-black;
          border: 1px solid $color-tertiary-black;

          &:hover {
            background-color: $color-secondary-yellow;
            color: $color-black;
          }
        }

        .active {
          color: $color-black;
          background-color: $color-secondary-yellow;
        }
      }

      .most-played-form {
        .input-wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        .filter {
          width: calc(50% - 5px);
          font-size: $paragraph-small;
          background-color: $color-tertiary-black;
          outline: none;
          border: none;
          margin-bottom: 10px;
          height: 35px;
          color: $color-white;
          padding: 0 12px;

          &:nth-of-type(1) {
            margin-right: 10px;
          }

        }
      }

      .react-datepicker-wrapper {
        width: calc(50% - 5px);

        &:first-of-type {
          margin-right: 10px;
        }

        input {
          width: 100%;
          height: 35px;
          padding: 0 12px;
          border: none;
          outline: none;
          font-size: $paragraph-small;
          color: $color-white;
          background-color: $color-tertiary-black;
        }
      }

      .react-datepicker__day--selected {
        color: $color-black;
        background-color: $color-secondary-yellow;
      }

      .most-played-list {
        margin: 20px 0;

        .list-header {
          display: none;
        }

        .item {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: $heading-small;
          background-color: $color-primary-green-dark;
          margin-bottom: 15px;

          &:nth-of-type(even) {
            background-color: $color-primary-green-lighter;
          }

          &:nth-of-type(odd) {
            background-color: $color-primary-green-light;
          }

          &:hover {
            color: $color-white;
          }

          .list-cell {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;

            &:nth-of-type(even) {
              background-color: $color-primary-green-light;
            }

            &:nth-of-type(odd) {
              background-color: $color-primary-green-lighter;
            }

            .label {
              margin-right: 20px;
              font-size: $paragraph-small;
            }

            .value {}

            .image-link {
              padding: 4px 0;
            }
          }

          .list-cell-mobile {}

          .list-cell-desktop {
            display: none;
          }
        }

        .artist {
          display: flex;
          align-items: center;
          padding-left: 6px;

          .img-container {
            width: 112.5px;
            height: 75px;
          }

          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }
        }

        .link {
          text-decoration: underline;
        }
      }

      .next,
      .previous {
        display: none;
      }
    }

    .top-column-mobile {
      display: none;
    }

    .search-column {
      width: 100%;

      .form {
        display: flex;
      }

      .input-group {
        position: relative;
        display: flex;
      }

      .reset-icon {
        position: absolute;
        top: 50%;
        right: 110px;
        transform: translateY(-50%);
        color: $color-grey;
        cursor: pointer;
      }

      .input {
        flex: 1;
        height: 35px;
        padding: 0 5px;
        font-size: $heading-small;
        color: $color-black;
        background-color: $color-white;
        border: none;
        outline: none;

        &::placeholder {
          color: $color-grey;
        }

        &::-ms-clear {
          display: none;
        }
      }

      .no-results {
        color: $color-white;
        font-weight: 300;
        font-size: $paragraph-medium;
        font-family: $font-sans, sans-serif;
        margin-left: 15px;
      }

      .submit-button {
        height: 35px;
        width: 100px;
        text-align: center;
        color: $color-black;
        font-size: $heading-small;
        background-color: $color-secondary-yellow;
        border: none;
        outline: none;
        cursor: pointer;
      }

      .categories-list {
        display: flex;
        flex-wrap: nowrap;
        margin: 15px 0;

        .item {
          flex:1;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-white;
          font-size: $paragraph-small;
          border: 1px solid $color-tertiary-black;
          background-color: $color-tertiary-black;
          cursor: pointer;
          width: calc(50% - 5px);
          margin-bottom: 10px;

          &:hover {
            color: $color-black;
            background-color: $color-secondary-yellow;
          }

          &:nth-of-type(even) {}

          &:nth-of-type(odd) {
            margin-right: 10px;
          }
        }

        .active {
          color: $color-black;
          background-color: $color-secondary-yellow;
        }
      }

      .date-filter {
        width: 100%;

        .filters {
          display: flex;

          div {
            flex: 1;
            margin-right: 10px;

            label {
              display: inline-block;
              margin-bottom: 10px;
            }

            select {
              width: 100%;
            }

            &:last-child {
              margin-right: 0px;
            }
          }
        }

        .filter {
          font-size: $paragraph-small;
          background-color: $color-tertiary-black;
          outline: none;
          border: none;
          height: 35px;
          color: $color-white;
          padding: 0 12px;

        }
      }

      .search-list {
        background-color: $color-primary-green-lighter;
        display: flex;
        flex-direction: column;
        padding: 14px;
        margin-bottom: 20px;

        .item {
          width: 100%;
          background-color: $color-primary-green-light;
          color: $color-white;
          font-size: $paragraph-small;
          margin-bottom: 10px;
          height: auto;
          display: flex;

          &:nth-of-type(odd) {
            margin-right: 10px;
          }
        }

        .link-container {
          width: 110px;
          background-color: $color-tertiary-black;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .link {
          text-decoration: underline;
        }

        .match-container {
          padding: 10px;
          flex: 1;
        }

        .match {
          display: flex;
          flex-direction: column;
        }

        .match-type {
          color: $color-grey;
          margin-top: 2px;
        }
      }
    }

    .search-column-mobile {
      display: none;
    }
  }
}

@media screen and (min-width: $breakpoint-mobile-small) {
  .playlists-search {
    .search-section {
      .tabs {
        .tab {
          padding: 8px 2px 6px;
          background-color: $color-primary-green-lighter;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .active-tab {
          background-color: $color-primary-green;
        }

        h2 {
          font-size: $heading-medium;
          display: flex;
          align-items: center;
        }

        .icon {
          display: block;
          margin: 0 6px;
        }
      }

      .top-column {
        .most-played-list {
          .item {
            .list-cell {
              // justify-content: flex-start;

              .label {
                width: 80px;
                margin-right: 0;
              }
            }
          }

          .artist {

            .img-container {
              width: 150px;
              height: 100px;
            }

            .img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .search-column {
        .input {
          padding: 0 15px;
        }

        .reset-icon {
          left: initial;
          right: 110px;
        }

        .search-list {
          margin: 20px 0;

          .item {
            height: 60px;
            align-items: center;
          }

          .link-container {
            height: 100%;
          }
        }

        .categories-list {
          .item {
            margin-bottom: 0;
            width: auto;

            &:nth-of-type(odd) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .playlists-search {
    .search-section {
      .tabs {
        width: 400px;

        .tab {
          padding: 8px 12px;
          justify-content: flex-start;
        }

        .active-tab {}

        h2 {}

        .icon {
          margin: 0 10px;
        }
      }

      .row {}

      .section-heading {}

      .top-column {
        .categories-list {
          .item {
            flex: initial;
            width: 170px;
            font-size: $heading-small;

            &:hover {}
          }

          .active {}
        }

        .most-played-form {
          .input-wrapper {
            max-width: 410px;
          }

          .filter {}
        }

        .react-datepicker-wrapper {
          input {}
        }

        .react-datepicker__day--selected {}

        .most-played-list {
          .col-10 {}

          .col-20 {}

          .col-25 {}

          .col-30 {}

          .col-40 {}

          .col-50 {}

          .col-60 {}

          .col-70 {}

          .col-80 {}

          .col-90 {}

          .list-header {
            span {}
          }

          .item {
            &:nth-of-type(even) {}

            &:nth-of-type(odd) {}

            &:hover {}

            div {}
          }

          .position {}

          .artist {
            .img {}
          }

          .link {}
        }

        .next,
        .previous {}
      }

      .top-column-mobile {}

      .search-column {
        .form {}

        .input-group {
          width: 370px;
        }

        .reset-icon {}

        .input {
          &::placeholder {}
        }

        .no-results {}

        .submit-button {}

        .categories-list {
          .item {
            &:hover {}
          }

          .active {}
        }

        .search-list {
          max-width: 500px;

          .item {
            height: 60px;

            &:nth-of-type(odd) {
              margin-right: 10px;
            }
          }

          .link-container {
            width: 110px;
            background-color: $color-tertiary-black;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }

          .link {
            text-decoration: underline;
          }

          .match-container {
            padding: 10px;
            flex: 1;
          }

          .match {
            display: flex;
            flex-direction: column;
          }

          .match-type {
            color: $color-grey;
            margin-top: 2px;
          }
        }
      }

      .search-column-mobile {
        display: none;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-tablet) {
  .playlists-search {
    .search-section {
      .tabs {
        .tab {}

        .active-tab {}

        h2 {}

        .icon {}
      }

      .row {}

      .section-heading {}

      .top-column {
        .categories-list {
          .item {
            font-size: $heading-medium;

            &:hover {}
          }

          .active {}
        }

        .most-played-form {
          .input-wrapper {
            max-width: none;
          }

          .filter {
            width: 160px;
            margin-bottom: 0;

            &:nth-of-type(2) {
              margin-right: 10px;
            }
          }
        }

        .react-datepicker-wrapper {
          width: auto;
          margin-right: 10px;

          input {
            width: 150px;
          }
        }

        .react-datepicker__day--selected {}

        .most-played-list {
          margin: 30px 0;

          .col-10 {
            width: 10% !important;
          }

          .col-20 {
            width: 20% !important;
          }

          .col-30 {
            width: 30% !important;
          }

          .col-50 {
            width: 50% !important;
          }

          .col-70 {
            width: 70% !important;
          }

          .list-header {
            background-color: $color-primary-green-light;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: $paragraph-small;

            span {
              padding: 12px;
            }
          }

          .item {
            align-items: center;
            flex-direction: row;
            margin-bottom: 0;

            &:nth-of-type(even) {}

            &:nth-of-type(odd) {}

            &:hover {}

            .list-cell {
              width: initial;
              align-items: center;
              justify-content: flex-start;

              &:nth-of-type(even) {
                background-color: transparent;
              }

              &:nth-of-type(odd) {
                background-color: transparent;
              }

              .label {
                display: none;
              }

              .value {}
            }

            .list-cell-mobile {
              display: none;
            }

            .list-cell-desktop {
              display: flex;
            }
          }

          .position {}

          .artist {
            .img {}
          }

          .link {
            padding: 6px 12px;
          }
        }

        .next,
        .previous {}
      }

      .top-column-mobile {}

      .search-column {
        .form {}

        .input-group {}

        .reset-icon {}

        .input {
          &::placeholder {}
        }

        .no-results {}

        .submit-button {}

        .categories-list {
          .item {
            &:hover {}
          }

          .active {}
        }

        .search-list {
          flex-direction: row;
          flex-wrap: wrap;
          max-width: none;
          margin: 30px 0;

          .item {
            width: calc(50% - 5px);

            &:nth-of-type(odd) {
              margin-right: 10px;
            }
          }

          .link-container {
            width: 110px;
            background-color: $color-tertiary-black;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }

          .link {
            text-decoration: underline;
          }

          .match-container {
            padding: 10px;
            flex: 1;
          }

          .match {
            display: flex;
            flex-direction: column;
          }

          .match-type {
            color: $color-grey;
            margin-top: 2px;
          }
        }
      }

      .search-column-mobile {
        display: none;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-small) {
  .playlists-search {

    .search-section {

      .wrapper {
        min-height: calc(100vh - 168px);
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-large) {
  .playlists-search {
    .search-section {

      .tabs {
        display: none;
      }

      .top-column-mobile {
        display: block;
      }

      .search-column-mobile {
        display: block;
      }

      .section-heading {
        display: block;
      }

      .top-column {
        width: auto;
        margin-right: 30px;
      }

      .search-column {
        width: 40%;

        .search-list {
          flex-direction: column;

          .item {
            width: 100%;
          }
        }
      }
    }
  }
}