@import '../abstracts/variables.scss';

.gallery {
  .images-section {
    background-color: $color-primary-green;

    .wrapper {
      width: 100%;
      max-width: 1200px;
      min-height: calc(100vh - 128px);
      margin: auto;
      display: flex;
      flex-direction: column;
      padding: 0 30px 15px;
      background-color: $color-primary-green-lighter;

      .page-heading {
        margin: 20px 10px 30px;
        color: $color-white;
        font-size: $heading-big;
        font-family: $font-serif, serif;
      }

      .image-gallery-slides {  

        .image-gallery-slide {
          height: calc(100vh - 400px);
          min-height: 400px;

          div {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        
        .image-gallery-image {
          width: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}