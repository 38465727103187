// Site colours
$color-primary-green: #2a2005;
$color-primary-green-light: #392B06;
$color-primary-green-lighter: #473607;
$color-primary-green-dark: #1f1804;
$color-primary-green-hover: #3c2e0a;
$color-secondary-yellow: #ffbb00;
$color-secondary-yellow-dark: #cc9600;
$color-secondary-yellow-light: #fff5d8;
$color-tertiary-black: #151002;
$color-grey: #a3a3a3;
$color-grey-medium: #cccecb;
$color-grey-light: #e8e8e8;
$color-grey-whitesmoke: #f5f5f5;
$color-black: #000;
$color-white: #fff;
$color-red: #cc0000;
$color-red-dark: #d43f3a;
$color-blue: #0a6bb5;

// Typography
$heading-extra-large: 28px;
$heading-large: 26px;
$heading-big: 22px;
$heading-medium: 18px;
$heading-small: 16px;

$paragraph-big: 16px;
$paragraph-medium: 15px;
$paragraph-small: 14px;

$font-serif: 'Lora';
$font-sans: 'Sailec';

// Breakpoints
$breakpoint-mobile-small: 480px;
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-header: 840px;
$breakpoint-desktop-small: 992px;
$breakpoint-desktop-large: 1200px;