.logout-btn {
    font-size: $heading-small;
    cursor: pointer;
  }

@media screen and (min-width: $breakpoint-mobile) {

}


@media screen and (min-width: $breakpoint-tablet) {

}

@media screen and (min-width: $breakpoint-desktop-small) {

}
@media screen and (min-width: $breakpoint-desktop-large) {
  
}
