@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.rotating {
  animation: rotating 2s linear infinite;
}

.sidebar {
   width: 100%;
   background-color: $color-primary-green-lighter;

  .list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .item {

    &:hover {
      background-color: $color-primary-green;
    }
  }

  .cache {
    padding: 10px 12px;
    color: $color-white;
    font-size: $heading-small;
    background-color: $color-primary-green;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  .cache-icon {
    margin-right: 8px;
  }

  .item-welcome {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid $color-primary-green-dark;
    width: 100%;
    min-height: 45px;

    .welcome {
      width: 100%;
      color: $color-white;
      text-align: center;
      font-size: $heading-small;
      margin: 12px 0;
    }
  }

  .link {
    display: block;
    padding: 10px 12px;
    text-decoration: none;
    color: $color-white;
    font-size: $heading-small;
  }

  .active {
    background-color: $color-secondary-yellow-dark;
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .sidebar {


    .list {

    }

    .item {


      &:hover {

      }
    }

    .item-welcome {


      .welcome {

      }
    }

    .link {

    }

    .active {

    }
  }

}

@media screen and (min-width: $breakpoint-tablet) {
  .sidebar {
    width: 240px;

    .list {
      flex-direction: column;
    }

    .item {


      &:hover {

      }
    }

    .item-welcome {
      width: auto;
      min-height: 80px;

      .welcome {
        width: 80%;
        font-size: $heading-medium;
        margin: 18px 0;
      }
    }

    .cache {
      padding: 15px 30px;
      font-size: $heading-medium;
      background-color: $color-primary-green-lighter;
      border-top: 2px solid $color-primary-green-dark;

      &:hover {
        background-color: $color-primary-green-lighter;
      }
    }

    .link {
      padding: 15px 30px;
      font-size: $heading-medium;
    }

    .active {

    }
  }
}


@media screen and (min-width: $breakpoint-desktop-small) {
  .sidebar {


    .list {

    }

    .item {


      &:hover {

      }
    }

    .item-welcome {


      .welcome {

      }
    }

    .link {

    }

    .active {

    }
  }
}

@media screen and (min-width: $breakpoint-desktop-large) {
  
}
