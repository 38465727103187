.terms {
  color: $color-white;

  h3 {
    margin: 35px 0 15px;
  }

  a {
    color: $color-white;
  }
}