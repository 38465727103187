.gallery-slider {
  .image-gallery-slide {
    outline: none;
  }
  
  .image-gallery-thumbnail.active, 
  .image-gallery-thumbnail:hover, 
  .image-gallery-thumbnail:focus {
    border: 4px solid $color-secondary-yellow;
  }

  .image-gallery-icon {
    padding: 0;
  }

  .image-gallery-icon:hover {
    color: $color-secondary-yellow;
  }

  .image-gallery-image {
    margin-bottom: 20px;
  }

  .image-gallery-description {

  }

  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(.9);
  }

  .image-gallery-svg {
    height: 50px;
    width: 50px;
    background: rgba(0, 0, 0, 0.4);
    outline: none;
  }

  .image-gallery-fullscreen-button {
    padding: 0;
    padding-bottom: 20px;

    .image-gallery-svg {
      height: 40px;
      width: 40px;
    }
  }
}