.homepage {

  .header-section {
    background-color: $color-tertiary-black;
    position: relative;

    .content-wrapper {
      max-width: 1200px;
      margin: auto;
      min-height: 450px;
      padding: 20px;
    }

    .text {
      height: 100%;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;

      .heading {
        font-size: $heading-extra-large;
        color: $color-white;
        line-height: 1.3;
        margin-bottom: 5px;
      }

      .paragraph {
        color: $color-grey-light;
        font-weight: 300;
        line-height: 1.3;
        white-space: pre-line;
      }

      .link {
        width: 100%;
        text-align: center;

      }

      .button {
        width: 100%;
        max-width: 350px;
        font-weight: 700;
        background-color: $color-secondary-yellow;
        font-size: $paragraph-small;
        outline: none;
        border: none;
        border-radius: 25px;
        height: 45px;
        text-transform: uppercase;
        margin-top: 15px;
        cursor: pointer;
      }
    }
    .img-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: all 1.5s;
      }

      .img-loading {
        opacity: 0;
      }
    }
  }

  .top-banners-section {
    background-color: $color-primary-green;

      .list {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      .item {
        background-color: $color-primary-green-lighter;   

        &:hover {
          background-color: $color-primary-green-hover;   
        }

        .heading {
          margin: 10px;
          line-height: 1.3;
        } 
        .topBannersImageWrap {
          width: calc(50% - 7.5px);
          .img-small {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 50% 10%;
          }
        }
      }

      .link {
        display: flex;
        height: 100%;
      }

      .top-banner-big {
        width: 100%;
        height: auto;
        margin-bottom: 15px;

        .link {
          flex-direction: column;
        }
  
        .heading {
          font-size: $heading-big;
        }
        .topBannersImageWrap {
          height: auto;
          width: 100%;
          .img-big {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 10%;
          }
        }
      }

      .top-banner-small {
        width: 100%;
        flex-direction: row;

        .heading {
          font-size: $heading-small;
        }

        h1 {
          flex: 1;
        }

        &:not(:last-of-type) {
          margin-bottom: 15px;
       }
      }
  }

  .bottom-banners-section {
    background-color: $color-primary-green-dark;

      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .item {
        width: calc(50% - 7.5px);
        height: auto;
        background-color: $color-primary-green-lighter;
        margin-bottom: 15px;

        &:hover {
          background-color: $color-primary-green-hover;
        }

        .link {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        .heading {
          font-family: $font-sans, sans-serif;
          font-weight: 400;
          font-size: $heading-small;
          padding: 10px;
          line-height: 1.3;
          max-width: 100%;
        }

        .img-container {
          width: 100%;
        }

        .img {
          width: 100%;
          height: auto;
          object-fit: cover;
          object-position: 50% 10%;
        }
      }
  }
}

@media screen and (min-width: $breakpoint-mobile-small) {
  .homepage {

    .top-banners-section {
      .link {
      }
      .item {
        .img-small {
        }
      }
      .top-banner-small {
        .heading {
        }
      }
    }
  }
}


@media screen and (min-width: $breakpoint-mobile) {
  .homepage {
    .section-heading {
    }

    .header-section {

      .content-wrapper {
        height: 520px;
      }
      
      .text {
        width: 100%;
        justify-content: center;

        .heading {

        }

        .paragraph {

        }

        .link {
          text-align: initial;
          width: auto;
        }

        .button {
          width: 280px;
          height: 40px;
          transition: all .4s;

          &:hover {
            background-color: $color-secondary-yellow-dark;
          }
        }
      }

      .img-container {
        .img {}
      }
    }

    .top-banners-section {
      .top-banner-small {
        .heading {
          font-size: $heading-medium;
        }
      }
    }

    .bottom-banners-section {
      .list {}
      .item {
        .heading {}
        .img {}
        &:not(:last-of-type) {}
      }
    }
  }
}

@media screen and (min-width: $breakpoint-tablet) {
  .homepage {
    .section-heading {}
    .header-section {
      .content-wrapper {}
      
      .text {
        width: 30%;
      }
    }

    .top-banners-section {
      .list {
        flex-direction: row;
      }

     .item {
        .heading {
          margin: 20px;
        } 
        .topBannersImageWrap {
          width: 100%;
          height: 150px;
          .img-small {
            width: 100%;
            height: 100%;
          }
        }
      }

      .top-banner-big {
        height: auto;
        min-height: 460px;
        width: calc(50% - 6.6px);
        margin-right: 10px;
        margin-bottom: 0;
        .topBannersImageWrap {
          height: 320px;
          .img-big {
          }
        }
      }

      .top-banner-small {
        height: auto;
        min-height: 460px;
        width: calc(25% - 6.6px);

        .link {
          flex-direction: column;
        }
        
       &:not(:last-of-type) {
        margin-right: 10px;
        margin-bottom: 0;
       }
      }
    }

    .bottom-banners-section {

      .list {
        flex-wrap: nowrap;
      }

      .item {
        width: calc(100% / 6);
        height: auto;
        margin-bottom: 0;
        min-height: 250px;

        .img-container {
          width: 100%;
          height: 125px;
        }

        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 10%;
        }

        &:not(:last-of-type) {
          margin-right: 10px;
        }
        .heading {
          font-size: $heading-medium;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-small) {
  .homepage {

    .top-banners-section {
      .top-banner-big {
        width: calc(50% - 15px);
        margin-right: 30px;

        .heading {
          font-size: $heading-large;
        }
      }

      .top-banner-small {
        width: calc(25% - 22.5px);

        .heading {
          font-size: $heading-big;
        }

        &:not(:last-of-type) {
          margin-right: 30px;
        }
      }
    }

    .bottom-banners-section {

      .item {

        .heading {
          padding: 10px 15px;
        }

        .img-container {
          height: 150px;
        }

        .img {
          height: 100%;
        }

        &:not(:last-of-type) {
          margin-right: 30px;
        }
      }
    }
  }
}
@media screen and (min-width: $breakpoint-desktop-large) {
  
}

