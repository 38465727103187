.page-not-found {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  background-color:$color-primary-green;
  min-height: calc(100vh - 90px);

  .wrapper {
    display: flex;
    padding: 20px;
    background-color: $color-primary-green-lighter;
  }

  h1 {
    color: $color-secondary-yellow;
    font-size: 40px;
    margin-bottom: 10px;
  }
  .bottom-border {
    padding: 10px 0;
    border-bottom: 4px solid $color-secondary-yellow;
  }
  h3 {
    color: $color-white;
    font-size: $heading-medium;
  }

  .icon {
    height: 85px;
    width: 85px;
    margin-left: 20px;
    color: $color-secondary-yellow;
  }

}

@media screen and (min-width: $breakpoint-desktop-small) {
    .page-not-found {
    min-height: calc(100vh - 120px);
    padding-top: 8%;

    .wrapper {
      padding: 60px 80px;
    }

    h1 {
      font-size: 50px;
      margin-bottom: 10px;
    }
    .bottom-border {
      padding: 10px 0;
    }
    h3 {
      font-size: $heading-big;
    }

    .icon {
      height: 125px;
      width: 125px;
      margin-left: 50px;
    }

  }
}