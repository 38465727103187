.playlist-programme {
  .print {
    font-size: 10pt;
    display: none;
    background-color: white;

    table.playlist {
      border-collapse: collapse;
      margin: auto;

      .title h1 {
        text-align: center;
        font-weight: bold;
        margin-bottom: 38px;
      }

      h3 {
        font-weight: bold;
        font-size: 1.1rem;
      }

      .title,
      .radio,
      .date {
        td {
          padding-left: 0px;
          border: none;
        }
      }

      .date{
        h3{
          margin-bottom: 38px;
        }
      }

      td {
        padding: 5px 10px;
        border: 2px solid black;

        &:not(:first-child) {
          width: 243px;
        }

        widows: 10;
      }

      tr {
        height: 30px;
        page-break-inside: avoid;
        page-break-after: page;
      }

      thead {
        td {
          font-weight: bold;
        }
      }

      tbody {
        tr {
          td:first-child {
            text-align: center;
          }
        }
      }
    }
  }
  .playlist-section {
    color: $color-white;

    .section-heading {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    .helper-container {
      display: flex;
      flex-direction: column;
    }

    .left-column {
      width: 100%;
      margin-bottom: 0;

      .intro {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .image-wrapper {
          position: relative;
        }

        .credits {
          font-size: 12px;
          font-family: $font-sans, sans-serif;
        }

        .description-credits {
          margin-top: 10px;
        }

        .image-credits {
          margin-bottom: 15px;
        }

        .img-container {   
          min-height: 200px;
          max-height: 370px;
          cursor: pointer;
        }

        .img {
          cursor: pointer;
          display: block;
          height: 100%;
          width: 100%;
          max-width: 100%;
          max-height: 370px;
          object-fit: cover;
        }

        .link {
          width: 100%;
          text-align: center;
  
        }
  
        .button {
          width: 100%;
          max-width: 350px;
          font-weight: 700;
          background-color: $color-secondary-yellow;
          font-size: $paragraph-small;
          outline: none;
          border: none;
          border-radius: 25px;
          height: auto;
          padding: 10px;
          text-transform: uppercase;
          margin-top: 15px;
          cursor: pointer;
          line-height: initial;
        }

        .text {
          background-color: $color-primary-green-light;
          font-size: $paragraph-medium;
          color: $color-grey-medium;
          padding: 15px 20px;
          flex: 1;

          .heading {
            font-size: $heading-large;
          }
          .description {
            font-weight: 300;
            line-height: 1.6;
            white-space: pre-line;
          }
          a {
            color: $color-secondary-yellow;
          }
        }
      }
    }

    .right-column {
      // .more-playlists-sidebar {
      //   display: none;
      // }
      .playlist {
        .list-header {
          display: none;
        }

        .list-row { 
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          &:nth-child(even) {
            background-color: $color-primary-green-lighter;
          }

          &:nth-child(odd) {
            background-color: $color-primary-green-light;
          }

          .list-cell {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 12px;

            &:nth-of-type(even) {
              background-color: $color-primary-green-light;
            }

            &:nth-of-type(odd) {
              background-color: $color-primary-green-lighter;
            }

            .label {
              margin-right: 20px;
              font-size: $paragraph-small;
            }

            .value {}
          }

          .list-cell-primary {
            padding: 15px 12px;

            .link {
              text-decoration: underline;
            }
          }

          .list-cell-hide {
            display: none;
          }

          .track {
            display: flex;
            justify-content: flex-start;
            padding: 6px;

            .number {
              padding: 0;
              width: auto;
              margin-right: 18px;
            }

            .track-name {
              width: 100%;
              padding: 0;
            }
          }
        }
        
      }
    }
  }

}

@page {
  size: auto;
  margin:1.6cm;
}

@media print {
  .header {
    display: none;
  }

  .playlist-programme {
    .playlist-section {
      display: none;
    }
    .print{
      display: block;
    }
  }

  .footer {
    display: none;
  }
}


@media screen and (min-width: $breakpoint-mobile-small) {
  .playlist-programme {

    .playlist-section {
      .left-column {

      }

      .right-column {
        .playlist {
          .list-row { 
            .list-cell {
              .label {
                width: 120px;
                margin-right: 0;
              }
              .value {}
            }
          }
        }
      }
    }
  }
}


@media screen and (min-width: $breakpoint-tablet) {
  .playlist-programme {

    .playlist-section {
        .left-column {
          .intro {
             flex-direction: row;
            .img-container {
            }

            .img {
              width: 300px;
              height: auto;
            }
          }

        }

        .right-column {
          .playlist {
            .list-header {
              display: flex;
              background-color: $color-primary-green-light;

              span {
                padding: 12px;
                width: 25%;
                color: $color-white;
                font-size: $paragraph-small;
              }
            }

            .list-row {
              flex-direction: row;
              margin: 0;

              .list-cell {
                display: flex;
                width: 100%;
                align-items: center;
                padding: 8px 12px;

                &:nth-of-type(even) {
                  background-color: transparent;
                }

                &:nth-of-type(odd) {
                  background-color: transparent;
                }

                .label {
                  display: none;
                }

                .value {
                
                }
              }

              .list-cell-hide {
                display: flex;
                width: 100%;
                align-items: center;
                padding: 8px 12px;

                .label {
                  display: none;
                }
              }
            }
          }
        }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-small) {
  
  .playlist-programme {

    .playlist-section {

      .helper-container {
         flex-direction: row;
      }

      .left-column {
        width: 300px;
        margin-right: 30px;

        .intro {
          flex-direction: column;

          .img-container {

          }
          .img {
            max-height: none;
          }
          .text {
            .heading {}
          }
        }

      }
      .right-column {
        flex: 1;

        .playlist {
          .list-header {}

          .list-row {
            padding: 8px 0;
            font-size: $heading-small;
            margin: 0;

            &:nth-child(even) {
              background-color: $color-primary-green-lighter;
            }

            &:nth-child(odd) {
              background-color: $color-primary-green-light;
            }

            
            .list-block {
              padding: 12px;
              width: 25%;
              background-color: transparent;

              &:nth-child(even) {
                background-color:  transparent;
              }

              &:nth-child(odd) {
                background-color:  transparent;
              }

              span {
                padding: 0;
                width: 100%;
                color: $color-white;
                &.list-block-header {
                  display: none;
                }
                &.list-block-body {
                  display: block;
                  width: 100%;
                  padding-left: 0;
                  clear: both;
                }
              }
            }

            .list-block-50 {
              background-color: transparent;
              width: 50%;
              .link {
                width: 50%;
              }
              .playlist-track-name {
                width: 50%;
                .dividerInChief {
                  display: none;
                }
              }
            }

            .track {
              display: flex;
              justify-content: flex-start;
              padding: 6px;

              .number {
                padding: 0;
                width: auto;
                margin-right: 18px;
              }

              .track-name {
                width: 100%;
                padding: 0;
              }
            }
          }
          
        }
        // .more-playlists-sidebar {
        //   display: block;
        //   width: 200px;

        //   .heading {
        //     background-color: $color-black;
        //     color: $color-white;
        //     font-size: $heading-big;
        //     line-height: 1.4;
        //     padding: 20px 10px;
        //     display: block;
        //     text-align: center;
        //     border-bottom: 6px solid $color-secondary-yellow;
        //     margin-bottom: 15px;
        //   }

        //   .list {

        //     .item {
        //       margin-bottom: 15px;

        //       &:hover .description {
        //         background-color: $color-primary-green-hover;
        //       }
        //     }

        //     .img {
        //       width: 200px;
        //       height: 135px;
        //       object-fit: cover;
        //       display: block;
        //     }

        //     .placeholder-img {
        //       width: 200px;
        //       height: 135px;
        //       display: flex;
        //       align-items: center;
        //       justify-content: center;
        //       background-color: $color-primary-green;
        //       color: $color-grey;
        //     }

        //     .description {
        //       width: 200px;
        //       text-decoration: none;
        //       padding: 12px 8px;
        //       font-size: $heading-small;
        //       display: flex;
        //       flex-direction: column;
        //       justify-content: center;
        //       align-items: center;
        //       text-align: center;
        //       background-color: $color-primary-green-lighter;

        //       .track-name {
        //         margin-bottom: 6px;
        //       }

        //       .artist-name {
        //         color: $color-grey;
        //       }
        //     }
        //   }
        // }
      }
    }

  }
}

@media screen and (min-width: $breakpoint-desktop-large) {
  
}

