.dashboard-form {
  .react-datepicker-wrapper {
    margin-bottom: 0 !important;

    .react-datepicker__input-container {
      margin: 0;
    }
  }
  .react-datepicker-popper {
    z-index: 3;
  }
}


