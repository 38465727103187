.artists-list {

  .list-section {
    .search-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .letters-list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;

      .item {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        font-size: $paragraph-small;
        background-color: $color-tertiary-black;
        border: 1px solid $color-tertiary-black;
        cursor: pointer;

        &:hover {
          color: $color-black;
          background-color: $color-secondary-yellow;
        }
      }

      .active {
        color: $color-black;
        background-color: $color-secondary-yellow;
      }
    }

    .input-group {
      position: relative;
    }

    .reset-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: $color-grey;
      cursor: pointer;
    }
      
    .input {
      height: 35px;
      width: 100%;
      padding: 0 15px;
      font-size: $heading-small;
      color: $color-black;
      background-color:$color-white;
      border: 1px solid $color-primary-green;
      border:none;
      outline: none;

      &::placeholder {
        color: $color-grey;
      }

      &::-ms-clear {
          display: none;
      }
    }

      .no-results {
        color: $color-white;
        font-weight: 300;
        font-size: $paragraph-medium;
        font-family: $font-sans, sans-serif;
        margin-left: 15px;
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;

        .item {
          width: 100%;
          margin-bottom: 15px;
          background-color: $color-primary-green-lighter;
        }

        .link {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .img {
          display: block;
          height: 115px;
          width: 130px;
          object-fit: cover;
        }

        .artist {
          font-size: $heading-small;
          color: $color-white;
          margin: 0 10px;
        }
      }
  }

  .most-played-artists {
    background-color: $color-primary-green-dark;

     .section-wrapper {
      max-width: calc(100% - 40px);

      .section-heading {
        margin-bottom: 30px;
        color: $color-white;
        font-size: $heading-big;
        font-family: $font-serif, serif;
      }

      .slick-slider {

        .slider-item {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          outline: none;
        }

        .slider-item {
          .img {
            width: 200px;
            height: 115px;
            object-fit: cover;
          }

          .artist {
            height: 85px;
            font-size: $heading-small;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: $color-primary-green-lighter;
            width: 200px;
            padding: 0 8px;

          }
        }

        .slick-arrow {
          z-index: 2;
        }

        .slick-prev {
          left: -20px;
        }

        .slick-next {
          right: -20px;
        }
      }

      .list {
        display: flex;

        .item {
          width: calc(20% - 20px);
          margin: 10px;
          height: 190px;
          background-color: $color-primary-green-lighter;
        }
      }
    }
  }

}

@media screen and (min-width: $breakpoint-mobile-small) {
  .artists-list {

    .list-section {
      .search-container {
      }


      .list {

        .item {

        }

        .link {

        }

        .img {
          width: 170px;
        }

        .artist {
        }
      }

    }
    .most-played-artists {

      .section-wrapper {
       
        .section-heading {
        }

        .slick-slider {

          .slider-item {

          }

          .slider-item {
            .img {

            }

            .description {


              &:hover {
                color: $color-secondary-yellow;
              }
            }
          }

          .slick-prev {
            left: calc(50% - 160px);
          }

          .slick-next {
            right: calc(50% - 160px);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .artists-list {

    .list-section {
      .search-container {
      }

      .list {

        .item {

        }

        .link {

        }

        .img {

        }

        .artist {
        }
      }

    }
    .most-played-artists {

      .section-wrapper {
       
        .section-heading {
        }

        .slick-slider {

          .slider-item {

          }

          .slider-item {
            .img {

            }

            .description {


              &:hover {
                color: $color-secondary-yellow;
              }
            }
          }

          .slick-prev {
            left: -20px;
          }

          .slick-next {
            right: -20px;
          }
        }

      }
    }
  }
}

@media screen and (min-width: $breakpoint-tablet) {
  .artists-list {

  .list-section {
    .search-container {
      display: flex;
      margin-bottom: 30px;
    }

    .letters-list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;

      .item {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        font-size: $paragraph-small;
        background-color: $color-tertiary-black;
        border: 1px solid $color-tertiary-black;
        cursor: pointer;

        &:hover {
          color: $color-black;
          background-color: $color-secondary-yellow;
        }
      }

      .active {
        color: $color-black;
        background-color: $color-secondary-yellow;
      }
    }

    .input-group {
      position: relative;
      width: calc(50% - 10px);
    }

    .reset-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: $color-grey;
      cursor: pointer;
    }
      
    .input {
      height: 35px;
      width: 100%;
      padding: 0 15px;
      font-size: $heading-small;
      color: $color-black;
      background-color:$color-white;
      border: 1px solid $color-primary-green;
      border:none;
      outline: none;

      &::placeholder {
        color: $color-grey;
      }
    }

      .no-results {
        color: $color-white;
        font-weight: 300;
        font-size: $paragraph-medium;
        font-family: $font-sans, sans-serif;
        margin-left: 15px;
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
          width: calc(50% - 7.5px);
          background-color: $color-primary-green-lighter;

          &:hover {
            background-color: $color-primary-green-hover;
          }

          .img {
            width: 130px;
          }
        }

        .link {
          display: flex;
          align-items: center;
          width: 100%;
        }
      }
  }

  .most-played-artists {
    background-color: $color-primary-green-dark;

     .section-wrapper {

      .section-heading {
        margin-bottom: 30px;
        color: $color-white;
        font-size: $heading-big;
        font-family: $font-serif, serif;
      }

      .slick-slider {

        .slider-item {
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          outline: none;
        }

        .slider-item {
          .link {
            &:hover .artist {
              background-color: $color-primary-green-hover;
            }
          }

          .img {
            width: 200px;
            height: 115px;
            object-fit: cover;
          }

          .artist {}
        }

        .slick-arrow {
          z-index: 2;
        }

        .slick-prev {
          left: -20px;
        }

        .slick-next {
          right: -20px;
        }
      }

      .list {
        display: flex;

        .item {
          width: calc(20% - 20px);
          margin: 10px;
          height: 190px;
          background-color: $color-primary-green-lighter;
        }
      }
    }
  }

}
}

@media screen and (min-width: $breakpoint-desktop-small) {
  .artists-list {

    .list-section {
      .search-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 30px;
      }

      .letters-list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        margin-right: 20px;

        .item {
          height: 31px;
          width: 31px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-white;
          font-size: $paragraph-small;
          background-color: $color-tertiary-black;
          border: 1px solid $color-tertiary-black;
          cursor: pointer;

          &:hover {
            color: $color-black;
            background-color: $color-secondary-yellow;
          }
        }

        .active {
          color: $color-black;
          background-color: $color-secondary-yellow;
        }
      }

      .input-group {
        position: relative;
        display: inline-block;
        width: 100%;
      }

      .reset-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $color-grey;
        cursor: pointer;
      }
        
      .input {
        height: 35px;
        width: 300px;
        padding: 0 15px;
        font-size: $heading-small;
        color: $color-black;
        background-color:$color-white;
        border: 1px solid $color-primary-green;
        border:none;
        outline: none;

        &::placeholder {
          color: $color-grey;
        }
      }

        .no-results {
          color: $color-white;
          font-weight: 300;
          font-size: $paragraph-medium;
          font-family: $font-sans, sans-serif;
          margin-left: 15px;
        }

        .list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .item {
            list-style-type: none;
            width: calc(50% - 15px);
            margin-bottom: 30px;
            background-color: $color-primary-green-lighter;
            display: flex;
            align-items: center;

            .img {
              height: 135px;
              width: 200px;
            }
          }

          .artist {
            font-size: $heading-medium;
            margin-left: 30px;
          }
        }
      
    }

    .most-played-artists {

       .section-wrapper {
        .section-heading {
          margin-bottom: 30px;
          font-size: $heading-big;
        }

        .slick-slider {

          .slider-item {
            display: flex !important;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            outline: none;
          }

          .slider-item {
            .img {
              width: 200px;
              height: 115px;
              object-fit: cover;
            }

            .artist {}
          }

          .slick-arrow {
            z-index: 2;
          }

          .slick-prev {
            left: -20px;
          }

          .slick-next {
            right: -20px;
          }
        }

        .list {
          display: flex;

          .item {
            width: calc(20% - 20px);
            margin: 10px;
            height: 190px;
            background-color: $color-primary-green-lighter;
          }
        }
      }
    }

  }
}

@media screen and (min-width: $breakpoint-desktop-large) {
  .artists-list {

    .list-section {
      .search-container {

      }

      .letters-list {
        align-self: center;
      }
    }

    .most-played-artists {

      .section-wrapper {
        max-width: 1160px;
      }
    }
  }
}

