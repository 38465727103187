.artist {

  .artist-section {

    .left-column {
      width: 100%;
      margin-bottom: 30px;
    }

    .right-column {
      width: 100%;
    }

    .img-container {
      height: 370px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bio {
      padding: 25px;
      color: $color-white;
      background-color: $color-primary-green-light;
      line-height: 1.8;

      p {
        margin: 0;
        white-space: pre-line;
      }
    }

    .gallery-slider {
      margin-top: 30px;

      .image-gallery-slide {
        max-height: 375px;
      }

      .image-gallery-image {
        margin: 0;
        object-position: 50% 10%;
      }

      .image-gallery-left-nav,
      .image-gallery-right-nav {
        display: none;
      }

      .image-gallery-thumbnails-wrapper {
        background-color: $color-tertiary-black;
        height: 100px;
        padding: 20px;
      }

      .image-gallery-thumbnails {
        height: 100%;
        display: flex;
        padding: 0;
      }

      .image-gallery-thumbnail {
        margin-right: 20px;
        border: none;
        width: auto;
        height: 60px;
      }

      .image-gallery-thumbnail-image {
        cursor: pointer;
        height: 60px;
        width: auto;
        object-fit: cover;
        object-position: 50% 10%;
      }

      .image-gallery-description {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 20px;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
      }

      .image-gallery-index {
        display: none;
      }

      .image-gallery-fullscreen-button {
        display: none;
      }
    }

    .related-artists,
    .artist-social-links {
      h2.heading {
        margin-bottom: 30px;
      }

      .related-artists-list {
        display: grid;
        column-gap: 1rem;
        row-gap: 0.3rem;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));

        .item {

          a,
          a:visited,
          a:hover,
          a:active {
            color: unset;
          }

          .img {
            padding: 3px;
            width: 100px;
            height: 76px;
            display: block;
            object-fit: cover;
            margin-right: 10px;
          }

          .placeholder-icon {
            width: 100px;
            margin-right: 10px;
            padding: 3px;
          }

          .img-container {
            height: 76px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-secondary-yellow;
            margin: 0;
          }

          .item-info {
            display: flex;
            flex-direction: column;
            flex: 1;

            span {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .youtube-video {
      margin-top: 30px;
    }

    .related-artists,
    .artist-social-links {
      margin-top: 30px;
    }

    .list {
      margin-top: 10px;

      .list-header {
        display: none;
      }

      .list-row {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        color: $color-white;
        font-size: $paragraph-medium;
        margin-bottom: 15px;

        .list-cell {
          width: 100%;
          padding: 15px 10px;
          display: inline-block;
        }

        .label {
          margin-bottom: 20px;
          text-transform: uppercase;
          font-size: $paragraph-small;
        }

        .value {
          width: 100%;
          display: flex;
          flex: 1;
          flex-wrap: wrap;

          .title {
            width: 100px;
          }
        }

        .album-cell {
          align-self: flex-start;
          display: flex;
          flex-direction: column;
          background-color: $color-primary-green-lighter;
        }

        .album-cover {
          object-fit: cover;
          width: 150px;
          height: auto;
          margin-right: 10px;
        }

        .track-container {
          display: flex;
          flex-direction: column;
          background-color: $color-primary-green-light;
        }

        .track-item {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          &:not(:last-of-type) {
            padding-bottom: 25px;
          }
        }

        .tracks-list {
          width: 100%;
        }

        .track {
          width: 50%;
        }

        .date {
          padding: 0 12px;
          width: 50%;
        }

        .link {
          text-decoration: none;
        }
      }

      .expand-menu {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
          width: 15px;
          height: 15px;
          min-width: 15px;
          min-height: 15px;
        }
      }

      .caret {
        margin-left: 5px;
      }

      .dates-list {
        margin-top: 15px;

        .item {
          margin-bottom: 8px;
        }
      }

      .dates-list-hidden {
        display: none;
      }
    }

    .no-results {
      color: $color-white;
      font-size: $heading-medium;
      font-weight: 300;
      font-family: $font-sans, sans-serif;
      margin-left: 20px;
    }

    .album-share-wrapper {
      width: 100%;
      position: relative;
      order: 3;
      margin-top: 15px;

      .share-button {
        height: 35px;
        width: 100%;
        border: none;
        background-color: $color-secondary-yellow;
        color: $color-black;
        cursor: pointer;
        margin-bottom: 0px;
        padding: 0 10px;
        transition: all .4s;

        &:hover {
          background-color: $color-secondary-yellow-dark;
        }
      }

      .album-share-buttons {
        height: 0;
        width: 100%;
        overflow: hidden;
        position: absolute;
        transition: all 0.3s ease-in;
        z-index: 1;

        &.show {
          height: 500px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-mobile-small) {
  .artist {

    .artist-section {

      .left-column {
        width: 100%;
        margin-bottom: 30px;
      }

      .right-column {
        width: 100%;
      }

      .img-container {
        height: 370px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .bio {
        padding: 25px;
        color: $color-white;
        background-color: $color-primary-green-light;
        line-height: 1.8;

        p {
          margin: 0;
          white-space: pre-line;
        }
      }

      .gallery-slider {
        margin-top: 30px;

        .image-gallery-slide {
          height: 450px;
        }

        .image-gallery-image {
          margin: 0;
          object-position: 50% 10%;
        }

        .image-gallery-left-nav,
        .image-gallery-right-nav {
          display: none;
        }

        .image-gallery-thumbnails-wrapper {
          background-color: $color-tertiary-black;
          height: 100px;
          padding: 20px;
        }

        .image-gallery-thumbnails {
          height: 100%;
          display: flex;
          padding: 0;
        }

        .image-gallery-thumbnail {
          margin-right: 20px;
          border: none;
          width: auto;
          height: 60px;
        }

        .image-gallery-thumbnail-image {
          cursor: pointer;
          height: 60px;
          width: auto;
          object-fit: cover;
          object-position: 50% 10%;
        }

        .image-gallery-description {
          display: flex;
          width: 100%;
          justify-content: center;
          padding: 20px;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
        }

        .image-gallery-index {
          display: none;
        }

        .image-gallery-fullscreen-button {
          display: none;
        }
      }

      .youtube-video {
        margin-top: 30px;
      }

      .artist-social-links {
        margin-top: 30px;
      }

      .list {
        margin-top: 10px;

        .list-header {
          display: none;
        }

        .list-row {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          color: $color-white;
          font-size: $paragraph-medium;
          margin-bottom: 15px;

          .list-cell {
            width: 100%;
            padding: 15px 10px;
            display: inline-block;
          }

          .label {
            width: 150px;
          }

          .value {
            align-items: center;
            justify-content: center;

            .title {
              flex-grow: 2;
            }
          }

          .album-cell {
            display: flex;
            align-self: flex-start;
            flex-direction: row;
            align-items: center;
          }

          .album-cover {
            width: 150px;
            height: auto;
            margin-right: 10px;
          }

          .track-container {
            display: flex;
            flex-direction: row;
          }

          .track-item {
            display: flex;
            align-items: flex-start;
            padding-bottom: 25px;
          }

          .track {
            width: 50%;
            display: inline-block;
          }

          .date {
            padding: 0 12px;
            width: 50%;
            display: inline-block;
          }

          .link {
            text-decoration: none;
          }
        }

        .expand-menu {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .caret {
          margin-left: 5px;
        }

        .dates-list {
          margin-top: 15px;

          .item {
            margin-bottom: 8px;
          }
        }

        .dates-list-hidden {
          display: none;
        }
      }

      .no-results {
        color: $color-white;
        font-size: $heading-medium;
        font-weight: 300;
        font-family: $font-sans, sans-serif;
        margin-left: 20px;
      }
    }
  }
}


@media screen and (min-width: $breakpoint-tablet) {
  .artist {

    .artist-section {

      .left-column {
        width: 100%;
        margin-bottom: 30px;
      }

      .right-column {
        width: 100%;
      }

      .img-container {
        height: 370px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .bio {
        padding: 25px;
        color: $color-white;
        background-color: $color-primary-green-light;
        line-height: 1.8;

        p {
          margin: 0;
          white-space: pre-line;
        }
      }

      .gallery-slider {
        margin-top: 30px;

        .image-gallery-slide {
          height: 375px;
        }

        .image-gallery-image {
          margin: 0;
          object-position: 50% 10%;
        }

        .image-gallery-left-nav,
        .image-gallery-right-nav {
          display: none;
        }

        .image-gallery-thumbnails-wrapper {
          background-color: $color-tertiary-black;
          height: 100px;
          padding: 20px;
        }

        .image-gallery-thumbnails {
          height: 100%;
          display: flex;
          padding: 0;
        }

        .image-gallery-thumbnail {
          margin-right: 20px;
          border: none;
          width: auto;
          height: 60px;
        }

        .image-gallery-thumbnail-image {
          cursor: pointer;
          height: 60px;
          width: auto;
          object-fit: cover;
          object-position: 50% 10%;
        }

        .image-gallery-description {
          display: flex;
          width: 100%;
          justify-content: center;
          padding: 20px;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
        }

        .image-gallery-index {
          display: none;
        }

        .image-gallery-fullscreen-button {
          display: none;
        }
      }

      .youtube-video {
        margin-top: 30px;
      }

      .artist-social-links {
        margin-top: 30px;
      }

      .list {
        margin-top: 10px;

        .list-header {
          display: flex;
          justify-content: space-around;
          background-color: $color-tertiary-black;
          color: $color-white;

          span {
            padding: 15px 10px;
            width: 33%;
            text-transform: uppercase;
            font-size: $paragraph-small;
          }
        }

        .list-row {
          flex-direction: row;
          justify-content: space-around;
          margin-bottom: 0;

          &:nth-child(even) {
            background-color: $color-primary-green-lighter;
          }

          &:nth-child(odd) {
            background-color: $color-primary-green-light;
          }

          .list-cell {
            width: 33%;
            padding: 15px 10px;
            display: inline-block;
          }

          .label {
            display: none;
          }

          .album-cell {
            align-self: flex-start;
            display: flex;
            align-items: center;
            background-color: transparent;

            .value {
              display: flex;
              text-align: center;

              p {
                text-align: center;
                order: 1;
                padding-right: 10px;
              }

              .buy-button {
                order: 3;
                margin: 15px 0;
                padding-right: 10px;
                font-size: 15px;
              }
            }
          }

          .album-cover {
            order: 2;
            margin-bottom: 1em;
            width: 150px;
            height: auto;
            padding-right: 10px;
          }

          .track-container {
            margin-top: 1em;
            width: 67%;
            background-color: transparent;
          }

          .track-item {
            display: flex;
            align-items: flex-start;
          }

          .track {
            width: 50%;
            display: inline-block;
          }

          .date {
            padding: 0 12px;
            width: 50%;
            display: inline-block;
          }

          .link {
            text-decoration: none;
          }
        }

        .expand-menu {
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            white-space: nowrap;
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }

        .caret {
          margin-left: 5px;
        }

        .dates-list {
          margin-top: 15px;

          .item {
            margin-bottom: 8px;
          }
        }

        .dates-list-hidden {
          display: none;
        }
      }

      .no-results {
        color: $color-white;
        font-size: $heading-medium;
        font-weight: 300;
        font-family: $font-sans, sans-serif;
        margin-left: 20px;
      }
    }
  }
}



@media screen and (min-width: $breakpoint-desktop-small) {
  .artist {

    .artist-section {

      .related-artists,
      .artist-social-links {
        h2.heading {
          margin-bottom: 30px;
        }
      }

      .left-column {
        width: 50%;
        margin-right: 40px;
        margin-bottom: 0;
      }

      .right-column {
        width: 50%;
      }

      .helper-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
      }

      .images {


        .img {}

        .caption {}
      }

      .artist-contact {

        .artist-name {}

      }

      .list {
        margin-top: 10px;

        .list-header {
          span {
            &:last-child {
              padding-left: 20px;
            }
          }
        }

        .list-row {
          .track {
            width: 53%;
          }

          .date {
            width: 47%;
          }

          .album-cell {
            .value {
              img {
                margin: 0 0 0 10px;
              }

              .album-share-wrapper {
                .list-wrapper {
                  width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-large) {}