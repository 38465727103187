.artist-social-links,
.album-share-wrapper {

  .list {
    width: 100%;
    background-color: $color-tertiary-black;
    margin-bottom: 0;
    padding: 10px;
    
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .item {
      width: 100%;
      margin-bottom: 5px;

      .link {
        height: 35px;
        background-color: $color-secondary-yellow;
        color: $color-black;
        padding: 0 0 0 10px;
        transition: all .4s;

        display: flex;
        align-items: center;

        &:hover {
          background-color: $color-secondary-yellow-dark;
        }

        .icon-label {
          width: 100%;
          margin-left: 8%;
          font-size: $paragraph-medium;
          text-align: left;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .artist-social-links {

    .list {
      justify-content: space-between;
      
      .item {
        width: 45%;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-tablet) {
  .artist-social-links {

    .list {
      .item {
        width: 48%;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-small) {
  .artist-social-links {

    .list {

      .item {
        width: 30%;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-desktop-large) {}
