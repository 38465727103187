.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 12;
}

.modal-main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  bottom: none !important;
  right: none !important;
  transform: translate(-50%, -50%) !important;
  max-width: 90% !important;
  max-height: 90% !important;
  height: auto !important;
  outline: 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);

  .img {
    max-width: 100%;
    max-height: 80vh;
  }
}

.display-block {
  opacity: 1;
  transition: all .3s;
  visibility: visible;
}

.display-none {
  transition: all .3s;
  opacity: 0;
  visibility: hidden;
}