.login-page {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 10%;
  background-color:$color-primary-green;
  min-height: calc(100vh - 90px);

  h1 {
    color: $color-white;
  }
}

@media screen and (min-width: $breakpoint-desktop-small) {
  .login-page {
    min-height: calc(100vh - 120px);
  }
}